import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
    NavStyles,
    NavDivStyles,
    NavLinkStyles,
    NavLinkUlStyles,
    NavLinkA1Styles,
    NavLinkA2Styles,
    NavDivHomeLinkStyles,
    NavDivHomeLogoStyles,
    docsButtonStyle,
    demoButtonStyle,
    activateKeyStyle,
    toolbarColor,
    currentPlanStyle,
    filePathStyle
} from '../styles/NavBarStyles.styles.tw';
import { Fade as Hamburger } from 'hamburger-react';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Toolbar, IconButton, Menu, MenuItem, Button, Breadcrumbs, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import Tooltip from '@mui/material/Tooltip';
import { gapi } from 'gapi-script';
import { useState, useEffect } from 'react';
import { getDatasets } from '../services/Endpoints';
import { DemoDatasets } from '../constants/demoDatasets';
import { getFileName } from './subcomponents/FileNameExtract';
import { ValidateUserPlan } from './subcomponents/ValidateUserDetails';
import { userDetailTypes } from '../common/types';

export default function NavBar() {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const [demo, setDemo] = useState<boolean>(false);
    const [demoDataset, setDemoDataset] = useState<string>('no');
    const [renderHamburger, setRenderHamburger] = useState(isMobile());
    const [isOpen, setOpen] = useState(false);
    const [userData , setUserData] = useState<userDetailTypes>();

    useEffect(() => {
        fetchDemoDataset();
        fetchUserDetails();
    }, []);

    function isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    localStorage.setItem('resolution', renderHamburger ? 'mobile' : 'desktop');

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchUserDetails = async () => {
        setUserData(await ValidateUserPlan())
    }

    const fetchDemoDataset = async () => {
        const result = await checkDemoDataset();
        setDemoDataset(result);
        !demoDataset && (localStorage.getItem('show_demo') === 'true') && localStorage.removeItem('show_demo')
    };

    const checkDemoDataset = async () => {
        let check = false;
        try {
            const response = await getDatasets(1);
            const data = await response.json();
            check = data.dataSets.some((data: { id: string }) => DemoDatasets.includes(data.id));
        } catch (error) {
        }
        return check ? 'yes' : 'no';
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            if (isUserSignedInViaGoogle()) {
                const auth2 = gapi.auth2.getAuthInstance();
                if (auth2) {
                    await auth2.signOut();
                }
            }
            sessionStorage.clear();
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    };

    const isUserSignedInViaGoogle = () => {
        const isGoogleUser = localStorage.getItem('isGoogleUser') === 'true';
        return isGoogleUser;
    };

    const checkPage = () => {
        const pages: string[] = ['/dashboard', '/datasetdetails', '/viewimages', '/exploredataset', '/analytics', '/settings', '/dataaudit','/organizationdetails','/workflow'];
        return pages.includes(location.pathname);
    };

    const checkDemo = () => {
        return localStorage.getItem('show_demo');
    };

    const checkDemoStatus = () => {
        if (localStorage.getItem('show_demo') === 'true') {
            localStorage.removeItem('show_demo');
        } else {
            localStorage.setItem('show_demo', String(!demo));
        }
    };

    const renderTooltipTitle = (membershipType:string) => {
        return membershipType === "Enterprise" ? "This is the enterprise version" : "This is the free version which restricts access to certain actions"
    }

    return (
        <NavStyles>
            <NavDivStyles>
                <NavDivHomeLinkStyles href="/">
                    <NavDivHomeLogoStyles src="./images/Logo_.png" alt="Cortal Logo" width="300px" height="200px" />
                </NavDivHomeLinkStyles>
                {!checkPage() && <div style={{ display: `${renderHamburger ? 'block' : 'none'}` }}><Hamburger color="#fff" toggled={isOpen} toggle={setOpen} /></div>}
                <NavLinkStyles>
                    <NavLinkUlStyles>
                        {checkPage() && (
                            <Toolbar sx={toolbarColor}>
                                {/* {userData?.membership_type && (<Tooltip title={renderTooltipTitle} TransitionProps={{ timeout: 600 }}><Typography sx={currentPlanStyle}>{userData?.membership_type} Plan</Typography></Tooltip>)} */}
                                {userData?.membership_type && (<Typography sx={currentPlanStyle}>{userData?.membership_type} Plan</Typography>)}
                                <Breadcrumbs aria-label="breadcrumb" color="#f79009" sx={filePathStyle}>
                                    <Link color="#f79009" to={'/dashboard'}>
                                        DASHBOARD
                                    </Link>
                                    {(location.pathname !== '/dashboard' && location.pathname !== '/settings' && location.pathname !== '/organizationdetails' && location.pathname !== '/workflow' ) && <Link
                                        to={''}
                                        onClick={() => {
                                            if (location.pathname !== '/datasetdetails') { window.history.go(-1); return false; }
                                            else{window.location.reload()}
                                        }}
                                        color="#f79009"
                                    >   
                                        DATASET DETAILS
                                    </Link>}
                                    {['/viewimages', '/exploredataset', '/analytics', '/workflow' , '/settings', '/dataaudit', '/organizationdetails'].includes(location.pathname) && (<Typography color="inherit" sx={filePathStyle}>{getFileName(location.pathname.split('/')[1])}</Typography>)}
                                </Breadcrumbs>
                                <Button style={docsButtonStyle} onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://cortal-insight.stoplight.io/docs/cortal-insight/67fb491f16f99-getting-started', '_blank');
                                }}>DOCS</Button>
                                {demoDataset === 'yes' && (
                                    <Button style={demoButtonStyle} onClick={(e) => {
                                        setDemo(!demo);
                                        checkDemoStatus();
                                        window.location.reload();
                                    }}>{!checkDemo() ? 'DEMO' : 'EXIT DEMO'}</Button>
                                )}
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}><NavLink to='/' onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://cortal-insight.stoplight.io/', '_blank');
                                    }}> About Us </NavLink></MenuItem>
                                    <MenuItem ><NavLink to="/settings" style={activateKeyStyle}>Activate Key</NavLink></MenuItem>
                                    <MenuItem ><NavLink to="/organizationdetails" style={activateKeyStyle} state={{organizationName:userData?.organization_name}}>Organization Details</NavLink></MenuItem>
                                    <MenuItem ><NavLink to="/workflow" style={activateKeyStyle} state={{organizationName:userData?.organization_name }}>Workflow (Beta)</NavLink></MenuItem>
                                    <MenuItem onClick={handleSignOut}>
                                        Sign Out
                                        <ExitToAppIcon fontSize="small" />
                                    </MenuItem>
                                </Menu>
                            </Toolbar>
                        )}
                        {(location.pathname === '/' && !renderHamburger) && (
                            <>
                                <li>
                                    <NavLinkA1Styles>
                                        <NavLink to='/' onClick={(e) => {
                                            e.preventDefault();
                                            window.open('https://cortal-insight.stoplight.io/', '_blank');
                                        }}> About Us </NavLink>
                                    </NavLinkA1Styles>
                                </li>
                                <li>
                                    <NavLinkA2Styles href='https://tally.so/r/w4KpeO'>Join Waitlist</NavLinkA2Styles>
                                </li>
                            </>
                        )}

                        {(location.pathname === '/login' && !renderHamburger) && (
                            <>
                                <li>
                                    <NavLinkA1Styles>
                                        <NavLink to='/login' onClick={(e) => {
                                            e.preventDefault();
                                            window.open('https://cortal-insight.stoplight.io/', '_blank');
                                        }}> About Us </NavLink>
                                    </NavLinkA1Styles>
                                </li>
                                <li>
                                    <NavLinkA2Styles href='https://tally.so/r/w4KpeO'>Join Waitlist</NavLinkA2Styles>
                                </li><li>
                                </li></>
                        )}
                    </NavLinkUlStyles>
                </NavLinkStyles>
            </NavDivStyles>
            <NavLinkUlStyles>
                {(location.pathname === '/' && isOpen) && (
                    <>
                        <li>
                            <NavLinkA1Styles>
                                <NavLink to='/' onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://cortal-insight.stoplight.io/', '_blank');
                                }}> About Us </NavLink>
                            </NavLinkA1Styles>
                        </li>
                        <li>
                            <NavLinkA2Styles href='https://tally.so/r/w4KpeO'>Join Waitlist</NavLinkA2Styles>
                        </li>
                    </>
                )}

                {(location.pathname === '/login' && isOpen) && (
                    <>
                        <li>
                            <NavLinkA1Styles>
                                <NavLink to='/login' onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://cortal-insight.stoplight.io/', '_blank');
                                }}> About Us </NavLink>
                            </NavLinkA1Styles>
                        </li><li>
                            <NavLinkA2Styles href='https://tally.so/r/w4KpeO'>Join Waitlist</NavLinkA2Styles>
                        </li><li>
                        </li></>
                )}
            </NavLinkUlStyles>
        </NavStyles>
    )
}
