const config = {
    Auth: {
        region: 'us-west-2', 
        userPoolId: 'us-west-2_dgMkVQ8Bi',
        userPoolWebClientId: '2bev4ml8g0eklpjm8hm07135ja', 
    },

    oauth: {
        domain: 'cortalinsights.auth.us-west-2.amazoncognito.com', //  Cognito domain
        scope: ['email', 'profile', 'openid'], // Standard scopes for Google Sign-In
        redirectSignIn: 'https://dev.dashboard.cortalinsight.com/oauthcallback', 
        redirectSignOut: 'https://dev.dashboard.cortalinsight.com',
        responseType: 'code', //  if we are using authorization code grant we can use 'code'
        clientId: '2bev4ml8g0eklpjm8hm07135ja'
      },
      
      federationTarget: 'COGNITO_USER_POOLS',
};

export default config;
